(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbOrder.directive:wbOrderHistory
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="wbOrder">
   <file name="index.html">
   <wb-order-history></wb-order-history>
   </file>
   </example>
   *
   */
  angular
    .module('wbOrder')
    .directive('wbOrderHistory', wbOrderHistory);

  function wbOrderHistory() {
    return {
      restrict: 'EA',
      scope: {
        limit: '@',
        showFilter: '@'
      },
      templateUrl: 'wb-order/directives/wb-order-history-directive.tpl.html',
      replace: false,
      controllerAs: 'wbOrderHistory',
      bindToController: true,
      controller: function (LoggedUser, OrderHistory, moment, Cart, $state, OrderService, $scope) {
        var vm = this
          , company = LoggedUser.getCompany()
          , orderHistory = new OrderHistory(company.getOrder())
          , duplicationIsDisabled;

        vm.filter = {
          dateFrom: null,
          dateTo: new Date()
        };

        vm.dateFrom = {
          year: '',
          month: '',
          day: ''
        };

        vm.dateTo = {
          year: '',
          month: '',
          day: ''
        };

        vm.currentYear = moment().year();

        vm.legends = [
          {
            type: 1,
            status: 'S'
          },
          {
            type: 2,
            status: 'S'
          }
        ];

        if (!_.isUndefined(vm.filter.dateFrom) && !_.isEmpty(vm.filter.dateFrom)) {
          var dateFromString = vm.filter.dateFrom.split('-');
          vm.dateFrom.year = parseInt(dateFromString[0], 10);
          vm.dateFrom.month = parseInt(dateFromString[1], 10);
          vm.dateFrom.day = parseInt(dateFromString[2], 10);
        }

        $scope.$watchCollection(function () {
          return vm.dateFrom;
        }, buildDateFrom);

        function buildDateFrom(date) {
          vm.filter.dateFrom = moment([date.year, date.month, date.day].join('-')).toDate();
        }

        if (!_.isUndefined(vm.filter.dateTo)) {
          var dateToString = moment(vm.filter.dateTo).format('YYYY-MM-DD').split('-');
          vm.dateTo.year = parseInt(dateToString[0], 10);
          vm.dateTo.month = parseInt(dateToString[1], 10);
          vm.dateTo.day = parseInt(dateToString[2], 10);
        }

        $scope.$watchCollection(function () {
          return vm.dateTo;
        }, buildDateTo);

        function buildDateTo(date) {
          vm.filter.dateTo = moment([date.year, date.month, date.day].join('-')).toDate();
        }

        orderHistory.setSize(vm.limit);

        vm.filterSubmit = function () {
          orderHistory.setFilter(vm.filter);
          orderHistory.refreshOrders();
        };
        vm.filterSubmit();

        vm.isProcessing = function isProcessing() {
          return orderHistory.isLoading();
        };

        vm.getOrders = function getOrders() {
          return orderHistory.getOrders();
        };

        vm.isPreviousPageAvailable = function isPreviousPageAvailable() {
          return orderHistory.hasPrevoiusPage();
        };

        vm.isNextPageAvailable = function isNextPageAvailable() {
          return orderHistory.hasNextPage();
        };
        vm.getPage = function getPage() {
          return orderHistory.getPage();
        };

        vm.nextPage = function nextPage() {
          orderHistory.nextPage();
          orderHistory.refreshOrders();
        };

        vm.previousPage = function previousPage() {
          orderHistory.previousPage();
          orderHistory.refreshOrders();
        };

        vm.getMinDate = function getMinDate() {
          var dateToFormat = moment(vm.filter.dateTo).subtract(6, 'M');
          return vm.formatValidationDate(dateToFormat);
        };

        vm.getMaxDate = function getMaxDate() {
          var dateToFormat = vm.filter.dateFrom ?
            moment(vm.filter.dateFrom).add(6, 'M') : moment(vm.filter.dateTo);
          return vm.formatValidationDate(dateToFormat);
        };

        vm.formatValidationDate = function formatValidationDate(date) {
          return moment(date).format('YYYY-MM-DD');
        };

        vm.isDuplicationDisabled = function isDuplicationDisabled() {
          return duplicationIsDisabled;
        };

        vm.generateNewOrderByItem = function generateNewOrderByItem(order) {
          duplicationIsDisabled = true;

          Cart
            .save({id: 2, order: order})
            .$promise
            .then(function () {
              duplicationIsDisabled = false;
              OrderService.updateCart();
              $state.go('typeSelector', {type: 2});
            });
        };
      }
    };
  }
}());
